<template>
    <div class="centerbox">
        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <!--列表-->
        <div class="Tablebox">          
            <el-table
            :data="tableData"
            border
            v-loading="listLoading"
            @select="dialogCheck"
            @row-click="selectCurrentRow"
            ref="tableList"
            style="width: 100%"
            class="custom-tbl"
            >
                <el-table-column  type="selection" width="50"> </el-table-column>
                <el-table-column  prop="Id" label="编号" width sortable> </el-table-column>
                <el-table-column  prop="Name" label="机构名称" width sortable> </el-table-column>
                <!-- <el-table-column  prop="TypeId" label="机构类型" width sortable> </el-table-column> -->
                <el-table-column  prop="AttributeId" label="机构属性" width sortable> </el-table-column>
                <el-table-column  prop="Phone" label="联系电话" width sortable> </el-table-column>
            </el-table>
            <!-- <el-pagination
                @current-change="handleCurrentChange"
                :current-page="paging.currentPage"
                :page-sizes="[10]"
                :page-size="paging.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="paging.total">
            </el-pagination> -->
        </div>
        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination
                layout="prev, pager, next"
                @current-change="handleCurrentChange"
                :page-size="paging.size"
            :total="paging.total"
            style="float: left"
            >     
            </el-pagination>
        </el-col>
        <!--新建角色弹窗-->
        <el-dialog title="新增机构" :visible.sync="dialogFormVisible" width="30%">
            <el-form ref="newform" :model="newform" label-width="100px" :rules="rules">
                <el-form-item label="机构名称" prop="Name">
                    <el-input v-model="newform.Name" placeholder="请填写机构名称"></el-input>
                </el-form-item>
                <!-- <el-form-item label="机构类型" label-width="100px" prop="AttributeId">
                    <el-select 
                        v-model="newform.TypeId" 
                        filterable 
                        placeholder="请选择机构类型" 
                        style="width:100%">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="机构属性" label-width="100px" prop="AttributeId">
                    <el-select 
                        v-model="newform.AttributeId" 
                        filterable 
                        placeholder="请选择机构属性" 
                        style="width:100%">
                        <el-option
                            v-for="item in attributelist"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系电话" prop="Phone">
                    <el-input v-model="newform.Phone" placeholder="请填写联系电话"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel()">取 消</el-button>
                <el-button type="primary" @click="determine('newform')">确 定</el-button>
            </div>
        </el-dialog>
         <!--编辑机构弹窗-->
         <el-dialog title="编辑机构" :visible.sync="editCompanys" width="30%">
            <el-form ref="basicdata" :model="basicdata" label-width="100px" :rules="rules">
                <el-form-item label="机构名称" prop="Name">
                    <el-input v-model="basicdata.Name" placeholder="请填写机构名称"></el-input>
                </el-form-item>
                <!-- <el-form-item label="机构类型" label-width="100px" prop="AttributeId">
                    <el-select 
                        v-model="basicdata.TypeId" 
                        filterable 
                        placeholder="请选择机构类型" 
                        style="width:100%">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="机构属性" label-width="100px" prop="AttributeId">
                    <el-select 
                        v-model="basicdata.AttributeId" 
                        filterable 
                        placeholder="请选择机构属性" 
                        style="width:100%">
                        <el-option
                            v-for="item in attributelist"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系电话" prop="Phone">
                    <el-input v-model="basicdata.Phone" placeholder="请填写联系电话"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel()">取 消</el-button>
                <el-button type="primary" @click="preservation('basicdata')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { instance,addSysOperateLog } from "../../../apis/apis.js";
    import { getButtonList } from "../../../promissionRouter";
    import Toolbar from "../../../components/Toolbar";
    import routes from '../../../router/index';
    export default {
        components: { Toolbar },
        data(){
            return{
                filters: {
                    name: "",
                },
                rules: {
                    name: [
                        { required: true, message: '请输入机构名称', trigger: 'blur' },
                    ],
                    attribute: [
                        { required: true, message: '请选择机构属性', trigger: 'blur' },
                    ],
                    phone: [
                        { required: true, message: '请输入机构联系电话', trigger: 'blur' },
                    ],
                },
                dialogFormVisible: false,
                editCompanys:false,
                newform: {},
                basicdata: {},
                buttonList: [],
                total: 0,
                //机构属性
                attributelist:[
                     {
                        value: '1',
                        label: '评估'
                    },{
                        value: '2',
                        label: '律所'
                    },{
                        value: '3',
                        label: '税务'
                    },{
                        value: '4',
                        label: '审计'
                    },{
                        value: '5',
                        label: '工程造价'
                    },{
                        value: '0',
                        label: '其它'
                    },
                ],
                listLoading: false,
                 //列表展示的值
                tableList:[],
                //机构和出包方
                options: [
                    {
                        value: '1',
                        label: '中介机构'
                    },
                    {
                        value: '2',
                        label: '出包方'
                    }
                ],            
                value:'', 
                //搜索关键词
                form: {
                    name: '',
                    handled:[],
                },
                //表单数据
                 tableData: [],
                //分页
                paging:{
                    currentPage:1,//当前加载页
                    size:10,//每页显示条目个数
                    total:0,//总条目数
                }                            
            }
        },
        created(){
            this.getorganlist(); //获取机构列表
        },
        methods: {
            dialogCheck(selection, row) {
                this.currentRow = null;
                this.$refs.tableList.clearSelection();
                if (selection.length === 0) {
                    return;
                }
                if (row) {
                    this.selectCurrentRow(row);
                }
            },
            selectCurrentRow(val) {
                if (val) {
                    this.currentRow = val;
                    this.$refs.tableList.clearSelection();
                    this.$refs.tableList.toggleRowSelection(val, true);
                }
            },
            //编辑
            handleClick() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                    message: "请选择要编辑的一行数据！",
                    type: "error",
                });
                    return;
                } 
                this.editCompanys=true
                this.getCompanys(row.Id);
            },
            handleCurrentChange(val) {
                this.paging.currentPage = val;
                this.getorganlist();
            },
            callFunction(item) {
                this.filters = {             
                    name: item.search,
                };       
                this[item.Func].apply(this, item);
            },
            //新建角色
            newbuild(){
                this.dialogFormVisible=true
                this.newform = {};
            },
            //获取详情
            getCompanys(id){
                instance.get('/Companys/GetInfo?id='+id).then(res=>{
                    if(res.data.success){
                        this.basicdata=res.data.response
                        this.attributelist.forEach (i=>{
                            if(this.basicdata.AttributeId==i.value){
                                this.basicdata.AttributeId=i.label
                            } 
                        });
                        this.options.forEach (i=>{
                            if(this.basicdata.TypeId==i.value){
                                this.basicdata.TypeId=i.label
                            } 
                        });                             
                    }
                });
            },
            //获取机构列表
            getorganlist(){
                instance.get('/Companys/Get?page='+this.paging.currentPage+'&intPageSize='+this.paging.size+'&typeid=1').then(res=>{
                    // res.data.response
                    if(res.data.success){
                        this.tableData=res.data.response.data; 
                        this.total = res.data.response.dataCount;   
                        this.tableData.forEach(item => {
                            this.attributelist.forEach (i=>{        
                                if(item.AttributeId==i.value){
                                    item.AttributeId=i.label
                                } 
                            });  
                            this.options.forEach (i=>{
                                if(item.TypeId==i.value){
                                    item.TypeId=i.label
                                } 
                            });                                                    
                        }); 
                    }
                    this.paging.total=res.data.response.dataCount
                });
            },
            cancel(){
                this.dialogFormVisible=false
                this.editCompanys=false
            },
            //新增
            determine(newform){
                this.$refs[newform].validate((valid) => {
                if (valid) {
                    this.newform.TypeId=1;
                    console.log(this.newform)
                    instance.post('/Companys/Post',this.newform).then(res=>{
                        this.$message({
                            message: '创建成功',
                            type: 'success'
                        });
                        this.getorganlist();//获取机构列表
                        this.dialogFormVisible=false   
                        this.editCompanys=false 
                        //操作日志
                        let param = {  ExternalId: res.data.response,Name:routes.history.current.name+"-新增" };
                        addSysOperateLog(param);
                    });
                } else {
                    this.$message.error('请正确填写信息');
                    }           
                });
            },
            //查询按钮
            onSubmit() {
                if(this.value =='null'){
                    var urll='/Companys/Get?typeid=1&page='+this.paging.currentPage+'&key='+this.filters.name+'&intPageSize='+this.paging.size;
                }else{
                    var urll='/Companys/Get?typeid=1&page='+this.paging.currentPage+'&key='+this.filters.name+'&intPageSize='+this.paging.size+'&AttributeId='+this.value;
                }
                instance.get(urll).then(res=>{
            
                    this.tableData=res.data.response.data;
                    this.tableData.forEach(item => {
                        this.attributelist.forEach (i=>{
                            if(item.AttributeId==i.value){
                                item.AttributeId=i.label
                            } 
                        });                                                        
                    }); 
                    this.paging.total=res.data.response.dataCount
                });
            },
            //编辑
            preservation(basicdata){
                this.editstate=false
            
                this.attributelist.forEach (i=>{
                    if(this.basicdata.AttributeId==i.label){
                        this.basicdata.AttributeId=i.value
                    } 
                });   
                this.options.forEach (i=>{
                    if(this.basicdata.TypeId==i.label){
                        this.basicdata.TypeId=i.value
                    } 
                }); 
                this.$refs[basicdata].validate((valid) => {
                if (valid) {
                    instance.put('/Companys/Put',this.basicdata).then(res=>{
                        console.log(res)
                        if(res.data.success){
                            this.$message({
                                message: '编辑成功',
                                type: 'success'
                            });
                            this.dialogFormVisible=false 
                            this.editCompanys=false
                            this.getorganlist();//获取机构列表
                            //操作日志
                            let param = {  ExternalId: res.data.response,Name:routes.history.current.name+"-编辑" };
                            addSysOperateLog(param);
                        }else{
                            this.$message.error('编辑错误请联系管理员');
                        }                      
                    });
                } else {
                    this.$message.error('请正确填写信息');
                    }
                });             
            },
            //删除
            handleDelete(){
                let row = this.currentRow;    
                if (!row) {
                    this.$message({
                     message: "请选择要删除的一行数据！",
                    type: "error",
                 });
                    return;
                }
                this.$confirm('您确定删除编号为：'+row.Id+'的文件吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => {
                    this.listLoading = true;
                    instance.delete('/Companys/Delete?id='+row.Id).then(res=>{
                        this.$message({
                            type: 'success',
                            message: '删除成功!'      
                        });
                        //操作日志
                        let param = {  ExternalId: row.Id,Name:routes.history.current.name+"-删除" };
                        addSysOperateLog(param);
                        location. reload();
                    });
                })
            },
            // //分页
            // //当前加载页 改变时会触发
            // handleCurrentChange(val) {
            //     console.log(`当前页: ${val}`);
            //     this.paging.currentPage=val;
            //     this.getorganlist();
            // }
        },
        mounted() {
            let routers = window.localStorage.router
            ? JSON.parse(window.localStorage.router)
            : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },      
    }
</script>
  
<style scoped>
    .centerbox{
        width: 100%;
        padding: 2rem;
        box-sizing: border-box;
        margin-bottom: 10rem;
         height: 100%;
    }
    .custom-tbl /deep/ .has-gutter .el-checkbox {
      display: none;
    }
</style>